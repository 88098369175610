<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_arpmd',
      headers: [
        [{
          label: 'Tahun Akademik',
          dataKey: 'tahun_akademik',
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Judul Penelitian',
          dataKey: 'judul_penelitian',
        }, {
          label: 'Nama Ketua Tim',
          dataKey: 'data_master_dosen.full_name',
        }, {
          label: 'Kepakaran Ketua Tim	',
          dataKey: 'kepakaran_ketua_tim',
        }, {
          label: 'Nama dan Identitas Dosen Anggota Penelitian	',
          dataKey: 'nama_dan_identitas_dosen_anggota_penelitian',
          render(data) {
            if (data.length && data[0]) {
              const researchMembers = data.map(member => `<li class="badge badge-success badge-sm d-inline-block text-left mb-1 px-1">${member}</li>`)
              return `<ul class="px-2 pt-1">${researchMembers}</ul>`.replace(/,/g, ' ')
            }
            return ''
          }
        }, {
          label: 'Nama dan Identitas Mahasiswa yang dilibatkan',
          dataKey: 'nama_dan_identitas_mahasiswa_yang_dilibatkan',
          render(data) {
            if (data.length && data[0]) {
              const researchMembers = data.map(member => `<li class="badge badge-success badge-sm d-inline-block text-left mb-1 px-1">${member}</li>`)
              return `<ul class="px-2 pt-1">${researchMembers}</ul>`.replace(/,/g, ' ')
            }
            return ''
          }
        }]
      ],
      form: {
        inputs: [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          type: 'textarea',
          placeholder: `${moment().format('YYYY')}/${moment().add(1, 'years').format('YYYY')}`,
          rules: 'required',
        }, {
          label: 'Judul Penelitian',
          name: 'judul_penelitian',
          dataKey: 'judul_penelitian',
          type: 'textarea',
        }, {
          label: 'Nama Ketua Tim',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'Kepakaran Ketua Tim	',
          name: 'kepakaran_ketua_tim',
          dataKey: 'kepakaran_ketua_tim',
          type: 'textarea',
        }, {
          label: 'Nama dan Identitas Dosen Anggota Penelitian	',
          name: 'nama_dan_identitas_dosen_anggota_penelitian',
          dataKey(data) {
            if (data.nama_dan_identitas_dosen_anggota_penelitian.length && data.nama_dan_identitas_dosen_anggota_penelitian[0]) {
              return data.nama_dan_identitas_dosen_anggota_penelitian
            }
            return []
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          multiple: true,
          fetchOptions(search, loading) {
            if (search.length >= 3) {
              return [search]
            }
          },
        }, {
          label: 'Nama dan Identitas Mahasiswa yang dilibatkan',
          name: 'nama_dan_identitas_mahasiswa_yang_dilibatkan',
          dataKey(data) {
            if (data.nama_dan_identitas_mahasiswa_yang_dilibatkan.length && data.nama_dan_identitas_mahasiswa_yang_dilibatkan[0]) {
              return data.nama_dan_identitas_mahasiswa_yang_dilibatkan
            }
            return []
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          multiple: true,
          fetchOptions(search, loading) {
            if (search.length >= 3) {
              return [search]
            }
          },
        }]
      },
    }
  },
}
</script>
